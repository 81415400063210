import {i18n} from "~/i18n"

export const FLUXER_EPOCH = 1672531200000

export const MAX_ATTACHMENTS_PER_MESSAGE = 10
export const MAX_CHANNEL_PINS = 100
export const MAX_EMBEDS_PER_MESSAGE = 10
export const MAX_FAVORITE_GIFS = 1000
export const MAX_REACTIONS_PER_MESSAGE = 30
export const MAX_SPACE_CHANNELS = 500
export const MAX_SPACE_EMOJIS_ANIMATED = 250
export const MAX_SPACE_EMOJIS_STATIC = 250
export const MAX_SPACE_INVITES = 1000
export const MAX_SPACE_ROLES = 250
export const MAX_SPACES = 200
export const MAX_USERS_PER_MESSAGE_REACTION = 5000
export const MAX_WEBHOOKS_PER_CHANNEL = 15
export const MAX_WEBHOOKS_PER_SPACE = 1000

export const AVATAR_MAX_SIZE = 10 * 1024 * 1024
export const AVATAR_EXTENSIONS = new Set(["jpeg", "png", "webp", "gif"])

export const EMOJI_MAX_SIZE = 384 * 1024
export const EMOJI_MAX_UNPROCESSED_SIZE = 1024 * 1024
export const EMOJI_EXTENSIONS = new Set(["jpeg", "png", "gif"])

export const ATTACHMENT_MAX_SIZE = 1024 * 1024 * 1024

export const USER_MENTION_REGEX = /<@!?(?<userId>\d+)>/g
export const CHANNEL_MENTION_REGEX = /<#(?<channelId>\d+)>/g
export const ROLE_MENTION_REGEX = /<@&(?<roleId>\d+)>/g

export const SYSTEM_USER_ID = 0n

export const DEFAULT_ROLE_COLOR = 0x99aab5
export const ROLE_COLORS = [
  0x1abc9c, 0x2ecc71, 0x3498db, 0x9b59b6, 0xe91e63, 0xf1c40f, 0xe67e22, 0xe74c3c, 0x95a5a6, 0x607d8b, 0x11806a,
  0x1f8b4c, 0x206694, 0x71368a, 0xad1457, 0xc27c0e, 0xa84300, 0x992d22, 0x979c9f, 0x546e7a,
]

export const ME = "@me"
export const SKIN_TONE_SURROGATES = ["🏻", "🏼", "🏽", "🏾", "🏿"]

export const APIErrorCodes = {
  GENERAL_ERROR: 0,

  UNKNOWN_CHANNEL: 1000,
  UNKNOWN_INVITE: 1001,
  UNKNOWN_MESSAGE: 1002,
  UNKNOWN_SPACE: 1003,
  UNKNOWN_SPACE_EMOJI: 1004,
  UNKNOWN_SPACE_MEMBER: 1005,
  UNKNOWN_SPACE_ROLE: 1006,
  UNKNOWN_SPACE_STICKER: 1007,
  UNKNOWN_USER: 1008,
  UNKNOWN_USER_NOTE: 1009,
  UNKNOWN_WEBHOOK: 1010,
  UNKNOWN_BETA_CODE: 1011,

  MISSING_ACCESS: 2000,
  MISSING_PERMISSIONS: 2001,
  BANNED_FROM_SPACE: 2002,

  MAX_CHANNEL_PINS: 3000,
  MAX_FAVORITE_GIFS: 3001,
  MAX_REACTIONS_PER_MESSAGE: 3002,
  MAX_SPACE_CHANNELS: 3003,
  MAX_SPACE_EMOJIS_ANIMATED: 3004,
  MAX_SPACE_EMOJIS_STATIC: 3005,
  MAX_SPACE_INVITES: 3006,
  MAX_SPACE_MEMBERS: 3007,
  MAX_SPACE_ROLES: 3008,
  MAX_SPACE_STICKERS: 3009,
  MAX_USER_SPACES: 3010,
  MAX_USERS_PER_MESSAGE_REACTION: 3011,
  MAX_WEBHOOKS_PER_CHANNEL: 3012,
  MAX_WEBHOOKS_PER_SPACE: 3013,

  UNAUTHORIZED: 4000,
  INPUT_VALIDATION_ERROR: 4001,
  CANNOT_SEND_EMPTY_MESSAGE: 4002,
  CANNOT_SEND_MESSAGE_TO_NON_TEXT_CHANNEL: 4003,
  CANNOT_EDIT_OTHER_USER_MESSAGE: 4004,
  CANNOT_EDIT_SYSTEM_MESSAGE: 4005,
  MFA_NOT_ENABLED: 4006,
  MFA_NOT_DISABLED: 4007,
} as const

export type APIErrorCode = (typeof APIErrorCodes)[keyof typeof APIErrorCodes]

export const StatusTypes = {
  ONLINE: 0,
  DND: 1,
  IDLE: 2,
  INVISIBLE: 3,
  OFFLINE: 4,
} as const

export type StatusType = (typeof StatusTypes)[keyof typeof StatusTypes]

export const OFFLINE_STATUS_TYPES = new Set([StatusTypes.OFFLINE, StatusTypes.INVISIBLE])

export const StatusTypeToString: Record<StatusType, string> = {
  [StatusTypes.ONLINE]: "online",
  [StatusTypes.DND]: "dnd",
  [StatusTypes.IDLE]: "idle",
  [StatusTypes.INVISIBLE]: "invisible",
  [StatusTypes.OFFLINE]: "offline",
}

export const StatusTypeToLabel: Record<StatusType, string> = {
  [StatusTypes.ONLINE]: i18n.Messages.ONLINE,
  [StatusTypes.DND]: i18n.Messages.DND,
  [StatusTypes.IDLE]: i18n.Messages.IDLE,
  [StatusTypes.INVISIBLE]: i18n.Messages.INVISIBLE,
  [StatusTypes.OFFLINE]: i18n.Messages.OFFLINE,
}

export const StatusTypeToDescription: Record<StatusType, string> = {
  [StatusTypes.ONLINE]: i18n.Messages.ONLINE_DESCRIPTION,
  [StatusTypes.DND]: i18n.Messages.DND_DESCRIPTION,
  [StatusTypes.IDLE]: i18n.Messages.IDLE_DESCRIPTION,
  [StatusTypes.INVISIBLE]: i18n.Messages.INVISIBLE_DESCRIPTION,
  [StatusTypes.OFFLINE]: i18n.Messages.OFFLINE_DESCRIPTION,
}

export const ThemeTypes = {
  DARK: 0,
  LIGHT: 1,
  SOLARIZED: 2,
  MIDNIGHT: 3,
  TWILIGHT: 4,
  SPARKLE: 5,
} as const

export type ThemeType = (typeof ThemeTypes)[keyof typeof ThemeTypes]

export const ThemeTypeToString: Record<ThemeType, string> = {
  [ThemeTypes.DARK]: "dark",
  [ThemeTypes.LIGHT]: "light",
  [ThemeTypes.SOLARIZED]: "solarized",
  [ThemeTypes.MIDNIGHT]: "midnight",
  [ThemeTypes.TWILIGHT]: "twilight",
  [ThemeTypes.SPARKLE]: "sparkle",
}

export const ChannelTypes = {
  SPACE_TEXT: 0,
  SPACE_DOCUMENT: 1,
  SPACE_LINK: 2,
} as const

export type ChannelType = (typeof ChannelTypes)[keyof typeof ChannelTypes]

export const SPACE_CHANNEL_TYPES = new Set([
  ChannelTypes.SPACE_TEXT,
  ChannelTypes.SPACE_DOCUMENT,
  ChannelTypes.SPACE_LINK,
])

export const SPACE_TEXT_CHANNEL_TYPES = new Set([ChannelTypes.SPACE_TEXT, ChannelTypes.SPACE_DOCUMENT])

export const MessageTypes = {
  DEFAULT: 0,
  CHANNEL_PINNED_MESSAGE: 1,
  USER_JOIN: 2,
  REPLY: 3,
} as const

export type MessageType = (typeof MessageTypes)[keyof typeof MessageTypes]

export const InviteTypes = {
  SPACE: 0,
} as const

export type InviteType = (typeof InviteTypes)[keyof typeof InviteTypes]

export const RecentMentionTypes = {
  USER: 0,
  EVERYONE: 1,
  ROLE: 2,
} as const

export type RecentMentionType = (typeof RecentMentionTypes)[keyof typeof RecentMentionTypes]

export const UserTypes = {
  DEFAULT: 0,
  AUTOMATED: 1,
  SYSTEM: 2,
} as const

export type UserType = (typeof UserTypes)[keyof typeof UserTypes]

export const UserRolePermissions = {
  NONE: 0n,
  ADMINISTRATOR: 1n << 0n,
} as const

export const UserRoleFlags = {
  NONE: 0,
  HIGHLIGHTED: 1 << 0,
} as const

export const ADMIN_USER_ROLE_ID = 1n

export const MessageFlags = {
  NONE: 0,
  MENTION_EVERYONE: 1 << 0,
  PINNED: 1 << 1,
  SUPPRESS_EMBEDS: 1 << 2,
  SUPPRESS_NOTIFICATIONS: 1 << 3,
} as const

export const MessageAttachmentFlags = {
  NONE: 0,
  SPOILER: 1 << 0,
  ANIMATED: 1 << 1,
} as const

export const MessageEmbedTypes = {
  RICH: 0,
  ARTICLE: 1,
  LINK: 2,
  IMAGE: 3,
  VIDEO: 4,
  AUDIO: 5,
  GIFV: 6,
} as const

export type MessageEmbedType = (typeof MessageEmbedTypes)[keyof typeof MessageEmbedTypes]

export const MessageEmbedMediaFlags = {
  NONE: 0,
  ANIMATED: 1 << 0,
} as const

export const Permissions = {
  NONE: 0n,
  CREATE_INVITE: 1n << 0n,
  KICK_MEMBERS: 1n << 1n,
  BAN_MEMBERS: 1n << 2n,
  ADMINISTRATOR: 1n << 3n,
  MANAGE_CHANNELS: 1n << 4n,
  MANAGE_SPACE: 1n << 5n,
  ADD_REACTIONS: 1n << 6n,
  VIEW_AUDIT_LOG: 1n << 7n,
  PRIORITY_SPEAKER: 1n << 8n,
  STREAM: 1n << 9n,
  VIEW_CHANNEL: 1n << 10n,
  SEND_MESSAGES: 1n << 11n,
  MANAGE_MESSAGES: 1n << 12n,
  EMBED_LINKS: 1n << 13n,
  ATTACH_FILES: 1n << 14n,
  READ_MESSAGE_HISTORY: 1n << 15n,
  MENTION_EVERYONE: 1n << 16n,
  USE_EXTERNAL_EMOJIS: 1n << 17n,
  CONNECT: 1n << 18n,
  SPEAK: 1n << 19n,
  MUTE_MEMBERS: 1n << 20n,
  DEAFEN_MEMBERS: 1n << 21n,
  MOVE_MEMBERS: 1n << 22n,
  USE_VAD: 1n << 23n,
  CHANGE_NICKNAME: 1n << 24n,
  MANAGE_NICKNAMES: 1n << 25n,
  MANAGE_ROLES: 1n << 26n,
  MANAGE_WEBHOOKS: 1n << 27n,
  MANAGE_SPACE_EXPRESSIONS: 1n << 28n,
  USE_APPLICATION_COMMANDS: 1n << 29n,
  REQUEST_TO_SPEAK: 1n << 30n,
  MANAGE_EVENTS: 1n << 31n,
  MANAGE_THREADS: 1n << 32n,
  CREATE_PUBLIC_THREADS: 1n << 33n,
  CREATE_PRIVATE_THREADS: 1n << 34n,
  USE_EXTERNAL_STICKERS: 1n << 35n,
  SEND_MESSAGES_IN_THREADS: 1n << 36n,
  MODERATE_MEMBERS: 1n << 37n,
  USE_SOUNDBOARD: 1n << 38n,
  CREATE_SPACE_EXPRESSIONS: 1n << 39n,
  CREATE_EVENTS: 1n << 40n,
  USE_EXTERNAL_SOUNDS: 1n << 41n,
  SEND_VOICE_MESSAGES: 1n << 42n,
  SEND_POLLS: 1n << 43n,
} as const

export const ALL_PERMISSIONS = Object.values(Permissions).reduce((acc, p) => acc | p, 0n)

export const DEFAULT_PERMISSIONS =
  Permissions.CREATE_INVITE |
  Permissions.ADD_REACTIONS |
  Permissions.STREAM |
  Permissions.VIEW_CHANNEL |
  Permissions.SEND_MESSAGES |
  Permissions.EMBED_LINKS |
  Permissions.ATTACH_FILES |
  Permissions.READ_MESSAGE_HISTORY |
  Permissions.USE_EXTERNAL_EMOJIS |
  Permissions.CONNECT |
  Permissions.SPEAK |
  Permissions.USE_VAD |
  Permissions.CHANGE_NICKNAME |
  Permissions.USE_APPLICATION_COMMANDS |
  Permissions.REQUEST_TO_SPEAK |
  Permissions.CREATE_PUBLIC_THREADS |
  Permissions.CREATE_PRIVATE_THREADS |
  Permissions.USE_EXTERNAL_STICKERS |
  Permissions.SEND_MESSAGES_IN_THREADS |
  Permissions.USE_SOUNDBOARD |
  Permissions.USE_EXTERNAL_SOUNDS |
  Permissions.SEND_VOICE_MESSAGES |
  Permissions.SEND_POLLS

export const SpaceRoleFlags = {
  NONE: 0,
  HOISTED: 1 << 0,
  MENTIONABLE: 1 << 1,
} as const

export const ChannelFlags = {
  NONE: 0,
  MUTED: 1 << 0,
} as const

export const ChannelOverwriteTypes = {
  ROLE: 0,
  USER: 1,
} as const

export type ChannelOverwriteType = (typeof ChannelOverwriteTypes)[keyof typeof ChannelOverwriteTypes]

export const FriendSourceFlags = {
  NONE: 0,
  EVERYONE: 1 << 0,
  MUTUAL_FRIENDS: 1 << 1,
  MUTUAL_SPACES: 1 << 2,
} as const

export const MessageStates = {
  SENT: 0,
  SENDING: 1,
  FAILED: 2,
} as const

export type MessageState = (typeof MessageStates)[keyof typeof MessageStates]

export const GatewayOpCodes = {
  DISPATCH: 0,
  HELLO: 1,
  IDENTIFY: 2,
  RESUME: 3,
  HEARTBEAT_PROBE: 4,
  HEARTBEAT_ACK: 5,
  PRESENCE_UPDATE: 6,
  INVALID_SESSION: 7,
} as const

export type GatewayOpCode = (typeof GatewayOpCodes)[keyof typeof GatewayOpCodes]

export const GatewayCloseCodes = {
  UNKNOWN_ERROR: 4000,
  UNKNOWN_OPCODE: 4001,
  DECODE_ERROR: 4002,
  NOT_AUTHENTICATED: 4003,
  AUTHENTICATION_FAILED: 4004,
  ALREADY_AUTHENTICATED: 4005,
  INVALID_SEQ: 4006,
  RATE_LIMITED: 4007,
  HEARTBEAT_ACK_TIMEOUT: 4008,
  INVALID_API_VERSION: 4009,
} as const

export type GatewayCloseCode = (typeof GatewayCloseCodes)[keyof typeof GatewayCloseCodes]
